import React from 'react';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import { SearchBar } from './';
//typography style
//sx={{ fontSize: {sm: 20, md: '25px',marginTop:'13px' }, color: 'ORANGE', fontWeight: 800 }}
const Navbar = () =>  (
  
  <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pt: 1, borderBottom: '1px solid #e3e3e3', position: 'fixed', top: 0, left: 0, background: 'white', width: '100%', zIndex: 100 }}>
  
    <Link to='/' style={{ textDecoration: 'none' }}>
   
      <Typography >
      <img src={logo} alt="Logo" style={{ height: '50px' }} />
      </Typography>
    </Link>
    <SearchBar />
    

  </Box>
);

export default Navbar;
